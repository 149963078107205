<template>
  <div class="app-calendar overflow-hidden">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title calendar_title float-left pr-1 mb-0">
              Calendar <span class="d-block d-md-none">
                <b-button
                  variant="success"
                  @click="showCreateSidebar"
                >
                  <span class="align-middle">New Booking</span>
                </b-button>

                <b-button
                  variant="primary"
                  class="printer_icon"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    class=""
                    size="16"
                  />
                </b-button>
              </span>
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="success"
          @click="showCreateSidebar"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Create New Booking</span>
        </b-button>

        <b-button
          variant="primary"
          class="printer_icon"
        >
          <feather-icon
            icon="PrinterIcon"
            class=""
            size="16"
          />
        </b-button>
      </b-col>
    </b-row>
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0 calendar_pb_none">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :custom-fields.sync="customFields"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
      <event-detail-view
        v-model="isEventDetailSidebarActive"
        :event="eventDetail"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BRow, BButton, BCol,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import EventDetailView from './calendar-event-handler/EventDetailView.vue'
import useCalendar from './useCalendar'
import { fetchActivityTypes } from './useActivityTypes'

// Notification

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    EventDetailView,

    BRow,
    BButton,
    BCol,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    fetchActivityTypes()

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      eventDetail,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      customFields,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDetailSidebarActive,
    } = useCalendar()

    fetchEvents()

    function showCreateSidebar() {
      customFields.value = store.state.calendar.calendarOptions.length ? store.state.calendar.calendarOptions[0].customFields : []
      const newArray = []
      customFields.value.forEach(arrayItem => {
        const obj = {}
        obj.key = arrayItem.label
        if (arrayItem.type === 'multi-select') {
          obj.value = []
        } else {
          obj.value = ''
        }
        newArray.push(obj)
      })

      const updatedActivityID = store.state.calendar.calendarOptions.length ? store.state.calendar.calendarOptions[0]._id : ''
      const timezone = process.env.VUE_APP_TIME_ZONE
      const date = moment().utcOffset(timezone).format('DD/MM/YYYY')
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { startDate: date, activityType: updatedActivityID, customFields: newArray })))

      isEventHandlerSidebarActive.value = true
    }

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      eventDetail,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      showCreateSidebar,
      customFields,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventDetailSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
