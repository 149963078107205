<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <div class="">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Activity Types</span>
        </h5>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item._id"
              name="event-filter"
              :value="item._id"
              class="mb-1 custom-control-unique-selector"
              :style="{ '--bg-color': item.color }"
            >
              {{ item.typeName }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <hr class="dividerHR mb-2">

        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Notable Bookings</span>
        </h5>
        <b-form-group>
          <b-form-checkbox-group
            v-model="notableSelection"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in notableOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  setup() {
    const {
      calendarOptions,
      notableOptions,
      selectedCalendars,
      notableSelection,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      notableOptions,
      selectedCalendars,
      notableSelection,
    }
  },
}
</script>
