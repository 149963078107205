<template>
  <div>
    <b-sidebar
      id="sidebar-event-detail"
      sidebar-class="sidebar-lg"
      :visible="isEventDetailSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-detail-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div v-if="event.extendedProps">
          <div v-if="event.extendedProps.activityTypeName == 'Event' && !event.extendedProps.customerName">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                Event Details
              </h5>
              <div>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
            </div>

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Event Name
              </h5>
              <p>{{ event.title ? event.title : '' }}</p>
            </div>

            <b-button-group>
              <b-button
                variant="flat-primary"
                :to="{ name: 'appointment-events-show', params: { id: event.extendedProps ? (event.extendedProps.editID ? event.extendedProps.editID : 0) : 0 } }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="EyeIcon"
                  size="16"
                />
                View Details
              </b-button>
              <b-button
                variant="flat-primary"
                :to="{ name: 'appointment-events-edit', params: { id: event.extendedProps ? (event.extendedProps.editID ? event.extendedProps.editID : 0) : 0 } }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="16"
                />
                Edit
              </b-button>
              <b-button
                variant="flat-primary"
                @click="doCopy(event.extendedProps ? (event.extendedProps.slug ? event.extendedProps.slug : 0) : 0)"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  size="16"
                />
                Copy URL
              </b-button>
            </b-button-group>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Title
              </h5>
              <p>{{ event.title ? event.title : '' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Event Name (中文)
              </h5>
              <p>{{ event.title ? event.title : '' }}</p>
              <h5 class="font-weight-bolder">
                Start Date - End Date
              </h5>
              <p>{{ event.start ? dateFormat(event.start) : '' }} - {{ event.end ? dateFormat(event.end) : '' }}</p>
              <h5 class="font-weight-bolder">
                Booking Period
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.bookingPeriod : '' }}</p>
              <h5 class="font-weight-bolder">
                Allow Waiting List
              </h5>
              <p>{{ event.extendedProps ? (event.extendedProps.waitingList === true ? 'Yes' : 'No') : '' }}</p>
              <h5 class="font-weight-bolder">
                Timeslots Booking
              </h5>
              <p class="text-capitalize">
                {{ event.extendedProps ? event.extendedProps.timeSlotBooking : '' }}
              </p>
              <h5 class="font-weight-bolder">
                Description
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.description : '' }}</p>
            </div>

            <hr class="dividerHR">

            <!-- Form Actions -->
            <div class="d-flex ml-auto action__block">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="flat-primary"
                @click="hide"
              >
                Back
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="button"
                :to="{ name: 'appointment-bookings-create', query: { event: event.extendedProps ? (event.extendedProps.editID ? event.extendedProps.editID : 0) : 0 } }"
              >
                Create Booking
              </b-button>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
              <h5 class="mb-0">
                Booking Details
              </h5>
              <div>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
            </div>

            <!-- Body -->
            <div
              class="px-2"
            >
              <div class="activity__top">
                <p><strong>Activity Type</strong></p>
                <b-button
                  class="mr-2 small-badge-button-activity-list"
                  :style="{ '--bg-color': event.backgroundColor ? event.backgroundColor : '#104D9D' }"
                  variant="none"
                  type="none"
                >
                  {{ event.extendedProps ? event.extendedProps.activityTypeName : '' }}
                </b-button>
              </div>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Title
              </h5>
              <p>{{ event.title ? event.title : '' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Customer Name
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.customerName : '' }}</p>
              <h5 class="font-weight-bolder">
                Mobile Number
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.customerContact : '' }}</p>
              <h5 class="font-weight-bolder">
                Email
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.customerEmail : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Start Date and Time
              </h5>
              <p>{{ event.start ? dateFormatWithTime(event.start) : '' }} - {{ event.end ? timeFormatFromDateString(event.end) : '' }}</p>
            </div>

            <hr class="dividerHR">
            <div
              v-if="event.extendedProps.bookingCustomFields"
            >
              <div
                v-if="event.extendedProps.bookingCustomFields.length"
                class="px-2"
              >
                <div
                  v-for="(opt, key) in event.extendedProps.bookingCustomFields"
                  :key="key"
                >
                  <h5 class="font-weight-bolder">
                    {{ opt.key }}
                  </h5>
                  <div v-if="Array.isArray(opt.value) && opt.value.length > 0">
                    <span
                      v-for="(option, index) in opt.value"
                      :key="index"
                    >
                      {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                    </span>
                  </div>
                  <div v-else>
                    <p>{{ opt.value }}</p>
                  </div>
                </div>
              </div>

              <hr class="dividerHR">
            </div>

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Duty Officer
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.dutyOfficer : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Description
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.description : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Remarks
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.remarks : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Payment Remarks
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.paymentRemarks : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <h5 class="font-weight-bolder">
                Agent Name
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.agentName : '-' }}</p>
              <h5 class="font-weight-bolder">
                Agent Code
              </h5>
              <p>{{ event.extendedProps ? event.extendedProps.agentCode : '-' }}</p>
            </div>

            <hr class="dividerHR">

            <!-- Form Actions -->
            <div class="d-flex ml-auto action__block">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="flat-primary"
                @click="hide"
              >
                Back
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="button"
                :to="{ name: 'appointment-bookings-edit', params: { id: event.extendedProps ? (event.extendedProps.editID ? event.extendedProps.editID : 0) : 0 } }"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="EditIcon"
                  size="16"
                />
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BButton, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BSidebar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventDetailSidebarActive',
    event: 'update:is-event-detail-sidebar-active',
  },
  props: {
    isEventDetailSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    doCopy(slug) {
      const url = this.$router.resolve({
        name: 'event-detail-frontend',
        params: { slug },
      })
      this.$copyText(window.location.origin + url.href).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>
