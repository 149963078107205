import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    notableOptions: [
      {
        color: 'warning',
        label: 'With Add-ons',
      },
      {
        color: 'danger',
        label: 'With Remarks',
      },
    ],
    selectedCalendars: [],
    notableSelection: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },

    SET_NOTABLE_SELECTED_EVENTS(state, val) {
      state.notableSelection = val
    },
    SET_ACTIVITY_TYPES(state, data) {
      state.calendarOptions = data
      const idArray = []
      data.forEach(item => {
        idArray.push(item._id)
      })
      state.selectedCalendars = idArray
    },
  },
  actions: {
    fetchActivityTypes({ commit }) {
      return axios
        .get('appointment/bookings/respond-with/activity-types')
        .then(response => {
          commit('SET_ACTIVITY_TYPES', response.data.activityTypes)
          return response
        })
    },
    fetchEvents(ctx, {
      calendars, notableOptions, view, startDate, endDate,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('appointment/bookings/calendar-view', {
            params: {
              calendars: calendars.join(','),
              notableOptions: notableOptions.join(','),
              view,
              startDate,
              endDate,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('appointment/bookings/store', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`appointment/bookings/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`appointment/bookings/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
