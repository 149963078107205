import store from '@/store'

// export default async function useActivityTypes() {
//   const { data } = await store
//     .dispatch('calendar/fetchActivityTypes')

//   let done = 0
//   if (data) {
//     done = 1
//   }

//   return {
//     done,
//   }
// }

const fetchActivityTypes = () => {
  store
    .dispatch('calendar/fetchActivityTypes')
}

// eslint-disable-next-line import/prefer-default-export
export { fetchActivityTypes }
